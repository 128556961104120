import gql from "graphql-tag";

// Mutations
export const CREATE_CARD = gql`
  mutation createCardHash(
    $customer_id: String
    $card_hash: String!
    $card_default: Boolean!
    $external_id: String!
    $card_bonus: Int
  ) {
    createCardHash(
      cardHash: {
        customer_id: $customer_id
        card_hash: $card_hash
        card_default: $card_default
        external_id: $external_id
        card_bonus: $card_bonus
      }
    ) {
      id
      brand
    }
  }
`;
